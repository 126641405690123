import React, { useEffect, useState } from 'react';
import { Input, NumberInput } from '@mantine/core';

function CustomInput({ num, callback, isValid }: any) {
  const [value, setValue] = useState<any>();

  const handleInputEvent = (value: number) => {
    setValue(value);
    const nextInput = document.getElementById(`${num + 1}-input`);
    if (nextInput && (value || value === 0)) nextInput.focus();
    callback(num, value);
  };

  useEffect(() => {
    if (isValid) {
      setTimeout(() => {
        setValue('🔒');
      }, num * 100);
    }
  }, [isValid]);

  return isValid ? (
    <Input size="md" className="w-[45px] text-center" value={value} />
  ) : (
    <NumberInput
      id={`${num}-input`}
      tabIndex={0}
      className="w-[45px] text-center"
      max={9}
      min={0}
      step={1}
      hideControls
      value={value}
      onChange={(val: number) => handleInputEvent(val)}
      size="md"
    />
  );
}

export default CustomInput;
