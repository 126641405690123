import styled from 'styled-components/macro';

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled('h3')`
  color: white;
`;

export const Text = styled('p')`
  font-size: 16px;
  font-weight: 400;
  font-style: italic;
  color: white;
`;

export const Card = styled.div`
  width: 350px;
  height: 450px;
  border-radius: 15px;
  background: #222327;
  padding: 22px;
  display: flex;
  flex-direction: column;
`;
