import { Button, Card, Container, Group, Image, Stack, Text, Title } from '@mantine/core';
import { IconBrandTwitter } from '@tabler/icons';
import React from 'react';

export default function Upgrading({ result, ape, setStep, setTiger, setApe, setType, setResult }: any) {
  const failed = ape?.json?.image === result?.json?.image;
  const url =
    'https://twitter.com/intent/tweet?text=' +
    encodeURIComponent('I have just Enlightened my @BabyApe_SC 🛸 Check this out!');

  const upgradeAnother = () => {
    setTiger(undefined);
    setType(undefined);
    setApe(undefined);
    setTimeout(() => {
      setResult(undefined);
    }, 1000);
    setStep(2);
  };

  const download = (e: any) => {
    const element = e.target;
    const file = new Blob([result?.json?.image], { type: 'image/*' });
    element.href = URL.createObjectURL(file);
    element.download = 'image.jpg';
    element.click();
  };

  if (result) {
    return (
      <div className="bg-contain bg-stars bg-no-repeat bg-center">
        <Container className="md:h-screen">
          <Group
            noWrap
            className="h-full justify-center items-center"
            direction="column"
            position="center"
            align="center"
          >
            <Group
              spacing={0}
              className="justify-center items-center"
              direction="column"
              position="center"
              align="center"
              noWrap
            >
              <Image width={180} src="logo.png" />

              {failed ? (
                <Text className="text-center">The Enlightenment has not been successful... but you can try again!</Text>
              ) : (
                <Title className="text-center" m={0}>
                  Welcome to Enlightenment
                </Title>
              )}
            </Group>

            <Group position="center" align="center">
              <Card radius="md" className="w-[250px]">
                <Card.Section>
                  <Image className="w-[250px] md:h-[auto]" src={ape?.json?.image || 'bg-ape.png'} />
                </Card.Section>
                <Stack pt={16} spacing="xs">
                  <Text size="sm">{ape?.name}</Text>
                  <Text size="sm">Evolution: OG</Text>
                </Stack>
              </Card>
              {!failed && (
                <Card radius="md" className="w-[250px]">
                  <Card.Section>
                    <Image
                      className="w-[250px] md:h-[auto]"
                      src={!ape || !result?.json?.image ? 'bg-ape.png' : result?.json?.image}
                    />
                  </Card.Section>
                  <Stack pt={16} spacing="xs">
                    <Text size="sm">{ape?.name}</Text>
                    <Text size="sm">Evolution: E2</Text>
                  </Stack>
                </Card>
              )}
            </Group>

            <Group>
              <Button onClick={upgradeAnother}>{failed ? 'Try Again' : 'Upgrade Another'}</Button>
              {!failed && (
                <Button
                  id="download-button"
                  component="a"
                  target="_blank"
                  download
                  href={result?.json?.image}
                  variant="outline"
                  onClick={(e) => download(e)}
                >
                  Download Image
                </Button>
              )}
              {!failed && (
                <Button component="a" target="_blank" href={url} leftIcon={<IconBrandTwitter />}>
                  Share on Twitter
                </Button>
              )}
            </Group>
            {!failed && (
              <Text className="max-w-2xl text-center  pb-4" size="xs">
                * NFTs in your wallet may take a moment to update. Check Solscan to see your Enlightened Baby Ape right
                away. If, after successful Enlightenment, you do not see your new Enlightened Baby on Solscan, empty
                your cache and reload the page.
              </Text>
            )}
          </Group>
        </Container>
      </div>
    );
  }
  return (
    <Group position="center" className="my-12">
      <Stack>
        <Image className="md:w-[550px]" src="/upgrade.gif" />
        <Stack className="text-center">
          <Title>Enlightening</Title>
          <Text>Please wait a moment... Do not refresh or close your page </Text>
        </Stack>
      </Stack>
    </Group>
  );
}
