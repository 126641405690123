import React from 'react';
// import * as anchor from '@project-serum/anchor';
// import { WalletModalProvider } from '@solana/wallet-adapter-ant-design';
// import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
// import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
// import {
//   LedgerWalletAdapter,
//   PhantomWalletAdapter,
//   SlopeWalletAdapter,
//   SolflareWalletAdapter,
//   SolletExtensionWalletAdapter,
//   SolletWalletAdapter,
//   TorusWalletAdapter,
// } from '@solana/wallet-adapter-wallets';
// import { clusterApiUrl } from '@solana/web3.js';
import 'antd/dist/antd.min.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import { Buffer } from 'buffer';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas, faTimes } from '@fortawesome/free-solid-svg-icons';
import { MantineProvider } from '@mantine/core';
import theme from './theme';
import { PrismicProvider } from '@prismicio/react';
import { client } from './prismic';
import Englighten from './pages/Englighten';
import Wallet from './providers/WalletProvider';
import { NotificationsProvider } from '@mantine/notifications';

library.add(fas, faTimes);

// const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

export const App = () => {
  // const rpcHost = 'https://holy-frosty-fire.solana-mainnet.quiknode.pro/fecb93c58549e7901cb7e8fde42a3eb5a083e97d/';
  // const rpcHost = anchor.web3.clusterApiUrl('mainnet-beta');
  // const connection = new anchor.web3.Connection(rpcHost ? rpcHost : anchor.web3.clusterApiUrl('mainnet-beta'), {
  //   confirmTransactionInitialTimeout: 60000,
  // });

  // const endpoint = useMemo(() => clusterApiUrl(network), []);
  // const wallets = useMemo(
  //   () => [
  //     new PhantomWalletAdapter(),
  //     new SlopeWalletAdapter(),
  //     new SolflareWalletAdapter({ network }),
  //     new TorusWalletAdapter(),
  //     new LedgerWalletAdapter(),
  //     new SolletWalletAdapter({ network }),
  //     new SolletExtensionWalletAdapter({ network }),
  //   ],
  //   [network]
  // );

  // if (typeof window !== 'undefined') {
  //   window.Buffer = Buffer;
  // } else {
  //   global.Buffer = Buffer;
  // }

  return (
    <Wallet>
      <MantineProvider theme={theme}>
        <NotificationsProvider>
          <div className="App">
            <BrowserRouter>
              <PrismicProvider client={client}>
                <Routes>
                  <Route path="/" element={<Englighten />} />
                </Routes>
              </PrismicProvider>
            </BrowserRouter>
          </div>
        </NotificationsProvider>
      </MantineProvider>
    </Wallet>
  );
};
