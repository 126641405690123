/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useState } from 'react';
import { ServerResponseData } from '../api/api';

export interface ApiProps {
  loading: boolean;
  request: (args?: any) => any;
  data: any;
}
const useApi = (apiFunc: (args: any) => Promise<ServerResponseData>): ApiProps => {
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(false);

  const request = async (...args: any) => {
    setLoading(true);
    try {
      // @ts-ignore
      const result = await apiFunc(...args);

      if (result.data) setData(result.data);
      else console.log(result.error);
    } catch (err: any) {
      return { error: err.message || 'Unexpected Error!' };
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, request };
};

export default useApi;
