import styled from 'styled-components/macro';

export const StepsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media (max-width: 1350px) {
    padding: 20px;
  }
`;

export const StepsWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-itens: flex-start;
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-itens: flex-start;
`;

export const Heading = styled('p')`
  font-size: 39px;
  font-weight: 600;
  color: white;
`;

export const Title = styled('h3')`
  color: white;
`;

export const Text = styled('p')`
  font-size: 16px;
  font-weight: 400;
  font-style: italic;
  color: white;
`;

export const DisplayBlock = styled.div`
  width: 100%;
  height: 80vh;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(step-display.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  padding: 22px;
  margin: 40px 0 20px;
  border-radius: 22px;
  background-color: rgba(12, 12, 12, 0.68);
`;

export const EnglightenButton = styled.button`
  width: 428px;
  height: 113px;
  font-size: 23px;
  font-weight: 600;
  border-radius: 7px;
  color: white;
  border-width; none;
  cursor: pointer;
  border: none;
  box-shadow: none;
  background: transparent;
  background-image: url(button-bg.png);
  background-size: 100% 100%;

  @media (max-width: 640px) {
    width: 80%;
    font-size: 18px;
    font-weight: 500;
  }

`;
