import api, { ServerResponseData } from './api';

const usersEndpoint = '/users';

const getApes = async ({ wallet }: { wallet: string }): Promise<ServerResponseData> =>
  api.get({ endpoint: `${usersEndpoint}/nfts/apes/${wallet}` });

const getTigers = async ({ wallet }: { wallet: string }): Promise<ServerResponseData> =>
  api.get({ endpoint: `${usersEndpoint}/nfts/tigers/${wallet}` });

const enlightenApe = async ({ data }: { data: any }): Promise<ServerResponseData> =>
  api.post({ endpoint: `${usersEndpoint}/nfts/enlighten`, data });

const getTransaction = async ({ data }: { data: any }): Promise<ServerResponseData> =>
  api.post({
    endpoint: `${usersEndpoint}/nfts/transaction`,
    data,
    responseType: 'application/octet-stream',
  });

const confirmTransaction = async ({ txid }: { txid: string }): Promise<ServerResponseData> =>
  api.get({
    endpoint: `${usersEndpoint}/transaction/${txid}`,
  });

const processTransaction = async ({ data }: { data: any }): Promise<ServerResponseData> =>
  api.post({ endpoint: `${usersEndpoint}/transaction`, data });

const getAliens = async ({ wallet }: { wallet: string }): Promise<ServerResponseData> =>
  api.get({ endpoint: `${usersEndpoint}/nfts/aliens/${wallet}` });

const reverseImage = async ({ data }: { data: any }): Promise<ServerResponseData> =>
  api.post({ endpoint: `${usersEndpoint}/nfts/reverse`, data });

const getReverseImageTransaction = async ({ wallet }: any): Promise<ServerResponseData> =>
  api.get({ endpoint: `${usersEndpoint}/reverse/transaction/${wallet}`, responseType: 'application/octet-stream' });

const processRevertTransaction = async ({ data }: { data: any }): Promise<ServerResponseData> =>
  api.post({ endpoint: `${usersEndpoint}/reverse/process/transaction`, data });

const endpoints = {
  getApes,
  getTigers,
  enlightenApe,
  getTransaction,
  confirmTransaction,
  processTransaction,
  getAliens,
  reverseImage,
  getReverseImageTransaction,
  processRevertTransaction,
};

export default endpoints;
