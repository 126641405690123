import styled from 'styled-components/macro';

export const ProcessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  row-gap: 15px;
  margin: 60px 0 0;
  color: white;
  height: 300px;
`;

export const Title = styled('h3')`
  color: white;
  text-align: center;
`;

export const Text = styled('p')`
  font-size: 16px;
  font-weight: 400;
  font-style: italic;
  color: white;
  text-align: center;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  column-gap: 13px;
  row-gap: 10px;
  padding: 0 10px;
`;

export const Input = styled.input`
  width: 41px;
  height: 41px;
  color: black;
  background: white;
  border-radius: 5px;
  box-shadow: none;
  outline: none;
  text-align: center;
`;

export const Dash = styled.div`
  width: 27px;
  height: 5px;
  background: white;
`;

export const ConnectButton = styled.button`
  width: 236px;
  height: 61px;
  font-size: 20px;
  font-weight: 600;
  border-radius: 7px;
  color: black;
  background: white;
  border-width; none;
  cursor: pointer;
  margin: 30px 0
`;
