import React, { useCallback, useEffect, useState } from 'react';
import { Image, Stack, Button, Group, Modal, Title, Text } from '@mantine/core';
import BabyTigerCard from '../../../common/BabyTigerCard';
import { Block, Content, DecisionButton, DecisionContent, StepsContainer, StepsWrapper } from './styles';
import SelectModal from './SelectModal';
import SaveModal from './SaveModal';
import SacrificeModal from './SacrificeModal';
import TigerModal from './TigerModal';
import { useWallet } from '@solana/wallet-adapter-react';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';

const StartingSteps = ({ ape, setApe, tiger, setTiger, setType, setStep, step, type }: any) => {
  const wallet = useWallet();
  const { setVisible } = useWalletModal();
  const [isSelect, setIsSelect] = useState<boolean>(false);
  const [tigerOpened, setTigerOpened] = useState<boolean>(false);
  const [opened, setOpened] = useState(false);

  const setSelectedApe = (item: any) => {
    setApe(item);
    setIsSelect(false);
  };

  const setSelectedTiger = (item: any) => {
    setTiger(item);
    setTigerOpened(false);
  };

  const handleNext = (type: string) => {
    setType(type);
    setOpened(true);
  };

  useEffect(() => {
    if (!wallet.connected) setVisible(true);
  }, []);

  const confirmSelection = () => {
    setStep(3);
    if (document.body.clientWidth < 500) window.scrollTo(0, 0);
    setOpened(false);
  };
  if (step !== 2) return <></>;
  return (
    <Stack className="h-full">
      <StepsContainer className={step === 2 ? 'my-auto' : 'h-0 md:h-auto my-auto'}>
        <StepsWrapper className={step === 2 ? '' : 'h-0 md:h-auto'}>
          <BabyTigerCard title="STEP 1" text="Choose a Baby Ape to enlighten">
            <Content>
              <Image
                className="w-full h-[306px] max-h-[306px]"
                src={ape ? ape?.json?.image : '/bg-ape.png'}
                alt="image"
                radius="sm"
              />
              <Button disabled={!wallet.connected} onClick={() => setIsSelect(true)}>
                {!wallet.connected ? 'Connect wallet' : 'Select your Ape'}
              </Button>
            </Content>
          </BabyTigerCard>

          <BabyTigerCard title="STEP 2" text="Choose your companion">
            <Content>
              <Image
                className="w-full h-[306px] max-h-[306px]"
                src={tiger?.json?.image || '/bg-tiger.png'}
                alt="image"
                radius="sm"
              />
              <Button disabled={!wallet.connected} onClick={() => setTigerOpened(true)}>
                {!wallet.connected ? 'Connect wallet' : 'Select your Tiger'}
              </Button>
            </Content>
          </BabyTigerCard>
          <BabyTigerCard title="STEP 3" text="Decide Your Companion's Fate">
            <DecisionContent>
              <Block>
                <Image src="/question-mark.png" alt="image" radius="sm" />
              </Block>
              <Stack className="w-full" spacing="xs">
                <Button size="md" className="w-full" disabled={!ape || !tiger} onClick={() => handleNext('save')}>
                  Save
                </Button>
                <Button size="md" className="w-full" disabled={!ape || !tiger} onClick={() => handleNext('sacrifice')}>
                  Sacrifice
                </Button>
              </Stack>
            </DecisionContent>
          </BabyTigerCard>
        </StepsWrapper>
        <SelectModal isSelect={isSelect} setIsSelect={setIsSelect} callback={setSelectedApe} />
        {/* <SaveModal isSave={isSave} setIsSave={setIsSave} />
        <SacrificeModal isSacrifice={isSacrifice} setIsSacrifice={setIsSacrifice} /> */}
        <TigerModal isSelect={tigerOpened} setIsSelect={setTigerOpened} callback={setSelectedTiger} />
      </StepsContainer>
      <Modal
        title={<Title order={2}>Confirm your companion&apos;s fate</Title>}
        opened={opened}
        onClose={() => setOpened(false)}
      >
        <Stack>
          <Text>
            🔥 Sacrifice: You decide to sacrifice your Tiger (the NFT is burnt), and you have a 100% success chance of
            Enlightening your Baby Ape.
          </Text>
          <Text>
            ❤️ Save: You have a 60% success chance to Enlighten your Baby Ape, but you will keep your Tiger. If you are
            not successful, you can try again!
          </Text>
          <Group grow>
            <Button onClick={confirmSelection}>
              {type === 'sacrifice' ? <Text>Sacrifice</Text> : <Text>Save</Text>}
            </Button>
            <Button variant="outline" onClick={() => setOpened(false)}>
              Go Back
            </Button>
          </Group>
        </Stack>
      </Modal>
    </Stack>
  );
};

export default StartingSteps;
