import React from 'react';
import { Card, CardWrapper, Text, Title } from './styles';

const BabyTigerCard = ({ children, title, text }: any) => {
  return (
    <CardWrapper>
      <Title>{title}</Title>
      <Text>{text}</Text>
      <Card>{children}</Card>
    </CardWrapper>
  );
};

export default BabyTigerCard;
