import React, { useEffect } from 'react';
import { Modal, Title } from '@mantine/core';
import ChooseApe from '../ChooseApe';
import useApi from '../../../hooks/useApi';
import endpoints from '../../../api/endpoints';
import { useWallet } from '@solana/wallet-adapter-react';

const SelectModal = ({ isSelect, setIsSelect, callback }: any) => {
  const { data, request: getApes, loading } = useApi(endpoints.getApes);
  const wallet = useWallet();

  useEffect(() => {
    if (wallet.publicKey) getApes({ wallet: wallet.publicKey?.toBase58() });
  }, [wallet?.publicKey, isSelect]);

  return (
    <>
      <Modal
        title={
          loading ? (
            <Title order={2}>Loading...</Title>
          ) : (
            <Title order={2}>{!data || !data.length ? 'Uh Oh...' : 'Select your chosen one'}</Title>
          )
        }
        opened={isSelect}
        onClose={() => setIsSelect(false)}
        centered
        size={528}
      >
        <ChooseApe loading={loading} callback={callback} apes={data} closeModal={() => setIsSelect(false)} />
      </Modal>
    </>
  );
};

export default SelectModal;
