/* eslint-disable indent */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  BackgroundImage,
  Button,
  Card,
  Anchor,
  Container,
  Divider,
  Group,
  Image,
  Modal,
  Stack,
  Text,
  Title,
  useMantineTheme,
  ScrollArea,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useWallet } from '@solana/wallet-adapter-react';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';
import { Connection, Transaction } from '@solana/web3.js';
import { IconArrowRight, IconArrowsRight, IconWallet } from '@tabler/icons';
import React, { useEffect, useState } from 'react';
import endpoints from '../../../api/endpoints';
import useApi from '../../../hooks/useApi';
import { getShortAddress } from '../../../utils/helpers';

const rpcHost = 'https://long-dawn-snowflake.solana-mainnet.quiknode.pro/29d84c5d45d688200f4bc6c0ce8bf96469d52c5b/';
const connection = new Connection(rpcHost);

export default function RevertLayout() {
  const { setVisible } = useWalletModal();
  const { data: aliens, request: getAliens } = useApi(endpoints.getAliens);
  const { data: reversedNft, request: reverseNft } = useApi(endpoints.reverseImage);

  const wallet = useWallet();
  const [opened, setOpened] = useState(false);
  const [nft, setNft] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [txid, setTxid] = useState<string>();
  const [error, setError] = useState<string[]>([]);
  const [success, setSuccess] = useState<any>();

  const theme = useMantineTheme();

  const setModalOpen = (selectedNft: any) => {
    // setKey(index);
    setNft(selectedNft);
    setOpened(true);
  };

  const reverseImage = async (id: string) => {
    try {
      await reverseNft({ data: { id } });
      setLoading(false);
      getAliens({ wallet: wallet.publicKey?.toBase58() });
      // setOpened(false);
      showNotification({ title: 'Success!', message: 'Your Ape has a new image!', color: 'green' });
      setSuccess(true);
    } catch (e) {
      console.log(e);
      showNotification({
        title: 'Error',
        message: 'There was an error please wait 5 minutes for it to update',
        color: 'red',
      });
    }
  };

  const index = nft?.name?.split('#')?.[1] || 1;
  const otherImage = !nft?.json?.image?.includes('aliens')
    ? `https://aliens-babyapesocialclub.s3.amazonaws.com/${index}.png`
    : `https://babyapesocialclub.s3.amazonaws.com/${index}.png`;

  useEffect(() => {
    if (wallet.publicKey) getAliens({ wallet: wallet.publicKey?.toBase58() });
  }, [wallet]);

  const confirmTransaction = async (txid: string, id: string, tries = 0) => {
    setLoading(true);
    if (tries === 30) {
      setError(['Transaction was not processed, please try again']);
      setTxid(txid);
      setLoading(false);
      showNotification({
        title: 'Error',
        message: 'Unable to confirm transaction, please check solscan.',
        color: 'red',
      });
      onClose();
      return;
    }
    const { data } = await endpoints.confirmTransaction({ txid });
    if (data && data.find((error: string) => error === 'Not Found')) {
      setTimeout(async () => {
        await confirmTransaction(txid, id, tries + 1);
      }, 5000 * tries);
    } else if (data && !data.length) {
      try {
        await reverseImage(id);
      } catch (e) {
        console.log(e);
        showNotification({
          title: 'Error',
          message: 'There was an error please wait 5 minutes for it to update',
          color: 'red',
        });
        onClose();
      }
    }
  };

  const getTransaction = async () => {
    try {
      setLoading(true);
      const result = await endpoints.getReverseImageTransaction({ wallet: wallet.publicKey?.toBase58() });
      // @ts-ignore
      const { data } = JSON.parse(result);
      if (!result) return;
      const tx = Transaction.from(Buffer.from(data));
      tx.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;
      if (!wallet?.publicKey || !wallet?.signTransaction) return;
      tx.feePayer = wallet.publicKey;
      const signed = await wallet.signTransaction(tx);

      const {
        data: { txid, error, result: revert },
      } = await endpoints.processRevertTransaction({
        data: { wallet: wallet.publicKey.toBase58(), signed: signed.serialize(), ape: nft.mint.address },
      });

      setTxid(txid);
      if (error) {
        setError(error);
        setLoading(false);
      } else if (txid) await confirmTransaction(txid, revert?.id, 0);
    } catch (e) {
      setLoading(false);
    }
  };

  const onClose = async () => {
    setSuccess(false);
    setOpened(false);
    setError([]);
    await getAliens({ wallet: wallet.publicKey?.toBase58() });
  };

  return (
    <BackgroundImage src="merch-bg.png">
      <div className="w-screen h-screen">
        <Container size={708} my={50}>
          <Card>
            <Stack>
              <Title>Swap your Ape&apos;s PFP</Title>

              <Text>
                You can transition your Ape&apos;s image forward or backwards. Revert back to your OG evolution but keep
                the perks of E2!
              </Text>
              <Text className="font-bold">Cost: 500 $DAB</Text>

              {!wallet.connected && (
                <Stack>
                  <Text>Connect your wallet</Text>
                  <Button onClick={() => setVisible(true)} className="w-fit" leftIcon={<IconWallet />}>
                    Connect
                  </Button>
                </Stack>
              )}
              <Divider />
              {wallet.connected && (
                <>
                  <Text>Select an Enlightened Ape or OG Ape to transition</Text>
                  <ScrollArea style={{ height: '350px' }}>
                    <Group>
                      {!aliens?.length && <Text color="red">No apes found</Text>}

                      {aliens?.map((item: any, index: any) => {
                        const key = item.name.split('#')[1];
                        return (
                          <Card sx={{ backgroundColor: theme.colors.dark[9] }} key={index} withBorder shadow="md">
                            <Card.Section>
                              <div className="relative h-[200px] w-[201px]">
                                <Image
                                  height={200}
                                  width={201}
                                  className="absolute animate-fade-slow"
                                  src={`https://babyapesocialclub.s3.amazonaws.com/${key}.png`}
                                  alt="staked header"
                                />
                                <Image
                                  height={200}
                                  width={201}
                                  className="absolute animate-fade"
                                  src={`https://aliens-babyapesocialclub.s3.amazonaws.com/${key}.png`}
                                  alt="staked header"
                                />
                              </div>
                            </Card.Section>
                            <Stack mt="md">
                              <Text size="xs">{item.name}</Text>
                              <Button onClick={() => setModalOpen(item)} className="w-full">
                                Select
                              </Button>
                            </Stack>
                          </Card>
                        );
                      })}
                    </Group>
                  </ScrollArea>
                </>
              )}
            </Stack>
          </Card>
          <Modal
            closeOnClickOutside={!loading}
            withCloseButton={false}
            opened={opened}
            onClose={() => setOpened(false)}
            title={
              <Title order={2}>
                {error?.length ? 'Error!' : loading ? 'Please wait a moment...' : success ? 'Success!' : 'Swap Images!'}
              </Title>
            }
          >
            {!loading && !success && !error?.length && (
              <Stack>
                <Group position="center">
                  <Stack spacing={0}>
                    <Text size="xs">From</Text>
                    <Text size="lg" className="font-bold">
                      {nft?.json?.image?.includes('aliens') ? 'E2' : 'OG'}
                    </Text>
                  </Stack>
                  <IconArrowRight color="white" size={30} />
                  <Stack spacing={0}>
                    <Text size="xs">To</Text>
                    <Text size="lg" className="font-bold">
                      {!nft?.json?.image?.includes('aliens') ? 'E2' : 'OG'}
                    </Text>
                  </Stack>
                </Group>
                <Group noWrap>
                  <Card>
                    <Card.Section>
                      <Image src={nft?.json?.image} />
                    </Card.Section>
                  </Card>

                  <Card>
                    <Card.Section>
                      <Image src={otherImage} />
                    </Card.Section>
                  </Card>
                </Group>

                <Group grow>
                  <Button onClick={getTransaction}>Swap Images</Button>
                  <Button onClick={() => setOpened(false)} variant="outline">
                    Cancel
                  </Button>
                </Group>
              </Stack>
            )}
            {loading && !error?.length && (
              <div>
                <Group position="center" className="my-12">
                  <Stack>
                    <Image className="md:w-[350px]" src="/upgrade.gif" />
                  </Stack>
                </Group>
              </div>
            )}
            {success && !loading && !error?.length && (
              <Stack>
                <Card>
                  <Card.Section>
                    <Image src={reversedNft?.json?.image || otherImage} />
                  </Card.Section>
                  <Stack spacing="xs">
                    <Stack spacing="sm" mt="md">
                      <Text>Mint</Text>
                      <Anchor target="_blank" href={`https://solscan.io/token/${nft.mint.address}`}>
                        <Group spacing={5}>
                          <Image src="/solscan-logo.png" width={20} height={20} />
                          <Text>{getShortAddress(nft.mint.address, 10)}</Text>
                        </Group>
                      </Anchor>
                    </Stack>
                    <Stack spacing="sm" mt="md">
                      <Text>Transaction Id</Text>
                      <Anchor target="_blank" href={`https://solscan.io/tx/${txid}`}>
                        <Group spacing={5}>
                          <Image src="/solscan-logo.png" width={20} height={20} />
                          <Text>{getShortAddress(txid, 10)}</Text>
                        </Group>
                      </Anchor>
                    </Stack>
                  </Stack>
                </Card>
                <Button onClick={onClose}>Close</Button>
              </Stack>
            )}
            {error && !!error.length && (
              <Stack>
                <Text>
                  There was an error swapping your image. Please contact your discord mods if the issue persists.{' '}
                </Text>
                {txid && (
                  <Stack spacing="sm" mt="md">
                    <Text>Transaction Id</Text>
                    <Anchor target="_blank" href={`https://solscan.io/tx/${txid}`}>
                      <Group spacing={5}>
                        <Image src="/solscan-logo.png" width={20} height={20} />
                        <Text>{getShortAddress(txid, 10)}</Text>
                      </Group>
                    </Anchor>
                  </Stack>
                )}
                <Text color="red">Errors:</Text>
                {error.map((message, i) => (
                  <Text key={i}>{message}</Text>
                ))}
                <Button onClick={onClose}>Close</Button>
              </Stack>
            )}
          </Modal>
        </Container>
      </div>
    </BackgroundImage>
  );
}
