import { showNotification } from '@mantine/notifications';
import axios from 'axios';
import { apiUrl, statusCodes } from '../constants';

// function formatErrorResponse(error) {
//   const customError = error;
//   customError.status = error.response ? error.response.status : null;

//   return customError;
// }

export interface ServerResponseData {
  success?: boolean;
  statusCode?: number;
  data?: any;
  error?: any;
  message?: string;
}

export interface Headers {
  'Content-Type': string;
  Accept: string;
  token?: string;
}

export interface ServerResponse {
  data: ServerResponseData;
}

export default async function api(options: any) {
  let localStorageToken;
  if (typeof window !== 'undefined') localStorageToken = localStorage.getItem('token');

  const { endpoint, token = localStorageToken, responseType, customBaseUrl, ...otherOptions } = options;
  const url = (customBaseUrl || apiUrl) + endpoint;
  const headers: Headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  try {
    if (token) headers.token = token;

    const { data }: ServerResponse = await axios({
      headers,
      responseType,
      url,
      withCredentials: true,
      validateStatus: () => true,
      ...otherOptions,
    });

    const { statusCode, error, message } = data;

    if (error && typeof window !== 'undefined') {
      const { href } = window.location;

      if (statusCode === statusCodes.UNAUTHORIZED) {
        const appUrl = process.env.REACT_APP_API_URL;
        const allowedRoutes = [`${appUrl}/`, `${appUrl}/login`, `${appUrl}/signup`];

        if (!allowedRoutes.includes(href)) window.location.href = `${appUrl}/login`;

        showNotification({
          id: 'generic-error',
          title: 'Error!',
          message: error || 'Unauthorized',
          color: 'red',
          disallowClose: false,
        });
      } else {
        showNotification({
          id: 'generic-error',
          title: 'Error!',
          message: error || message,
          color: 'red',
          disallowClose: false,
        });
      }
    }

    return data;
  } catch (error: any) {
    return { error: error.message, data: undefined, success: false };
  }
}

api.get = (options: any) => api({ ...options, method: 'GET' });

api.post = (options: any) => api({ ...options, method: 'POST' });

api.put = (options: any) => api({ ...options, method: 'PUT' });

api.patch = (options: any) => api({ ...options, method: 'PATCH' });

api.delete = (options: any) => api({ ...options, method: 'DELETE' });
