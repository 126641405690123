import React from 'react';
import { Card, Group, Image, ScrollArea, Stack, Button, Text, Skeleton } from '@mantine/core';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';

const ChooseApe = ({ buttonLabel, apes, callback, closeModal, loading }: any) => {
  const { setVisible } = useWalletModal();
  const handleSelectNewWallet = () => {
    closeModal(true);
    setVisible(true);
  };
  if (loading) {
    return (
      <ScrollArea style={{ height: '285px' }}>
        <Group>
          <Skeleton className="w-[47%] md:w-[31%] h-[223px]" height={224} />
          <Skeleton className="w-[47%] md:w-[31%] h-[223px]" height={224} />
          <Skeleton className="w-[47%] md:w-[31%] h-[223px]" height={224} />
        </Group>
      </ScrollArea>
    );
  }
  return (
    <Stack>
      {(!apes || !apes.length) && (
        <>
          <Text>No apes here to enlighten...</Text>
          <Text>Wrong wallet? Connect a different one now.</Text>
          <Group grow>
            <Button onClick={handleSelectNewWallet}>Swap wallets</Button>
            <Button onClick={closeModal} variant="outline">
              Cancel
            </Button>
          </Group>
        </>
      )}
      {!!apes && !!apes.length && (
        <ScrollArea style={{ height: '285px' }}>
          <Group>
            {apes?.map((item: any, index: any) => {
              return (
                <Card className="w-[47%] md:w-[31%]" key={index} withBorder shadow="md">
                  <Card.Section>
                    <Image width="100%" className="w-full" src={item.json.image} alt="image" radius="sm" />
                  </Card.Section>

                  <Button className="w-full mt-5" onClick={() => callback(item)}>
                    {buttonLabel || 'Select'}{' '}
                  </Button>
                </Card>
              );
            })}
          </Group>
        </ScrollArea>
      )}
    </Stack>
  );
};

export default ChooseApe;
