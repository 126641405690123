import { Button, Group, Input, NumberInput } from '@mantine/core';
import { useWallet } from '@solana/wallet-adapter-react';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';
import { IconCheck } from '@tabler/icons';
import React, { useCallback, useEffect, useState } from 'react';
import CustomInput from './CustomInput';
import { ProcessWrapper, Title, Text, InputWrapper, Dash } from './styles';
// 0 1 4 9 13 24 30
const Process = ({ setStep, step }: any) => {
  const initCode = localStorage.getItem('code')
    ? JSON.parse(localStorage.getItem('code') || '')
    : [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1];
  const [code, setCode] = useState([-1, -1, -1, -1, -1, -1, -1, -1, -1, -1]);
  const value = [0, 1, 4, 9, 1, 3, 2, 4, 3, 0];
  const [isValid, setValid] = useState(false);
  const [hasValues, setHasValues] = useState(false);
  // 0149132430
  const updateCode = (key: number, value: number) => {
    const newCode = JSON.parse(JSON.stringify(code));
    newCode[key] = value;
    setCode(newCode);
  };

  useEffect(() => {
    if (isValid && step === 1) {
      setTimeout(() => {
        setStep(2);
      }, 1000);
      localStorage.setItem('code', JSON.stringify(code));
    }
  }, [isValid]);

  useEffect(() => {
    if (step === 1) setTimeout(() => setCode(initCode), 1500);
  }, [step]);

  useEffect(() => {
    let valid = true;
    let values = true;
    code.forEach((v: any, index: any) => {
      if (value[index] !== v) valid = false;
      if (v < 0 || (!value[index] && value[index] !== 0)) values = false;
    });
    setValid(valid);
    setHasValues(values);
  }, [code]);

  return (
    <div className="flex items-center justify-center h-full">
      <ProcessWrapper>
        <Title>Enter The Code To Begin The Enlightenment Process</Title>
        <Text>Open Your Eyes And Find The Code...Before It’s Too Late</Text>
        <InputWrapper>
          <CustomInput isValid={isValid} callback={updateCode} num={0} />
          <Dash className="hidden md:block" />
          <CustomInput isValid={isValid} callback={updateCode} num={1} />
          <Dash className="hidden md:block" />
          <CustomInput isValid={isValid} callback={updateCode} num={2} />
          <Dash className="hidden md:block" />
          <CustomInput isValid={isValid} callback={updateCode} num={3} />
          <Dash className="hidden md:block" />
          <CustomInput isValid={isValid} callback={updateCode} num={4} />{' '}
          <CustomInput isValid={isValid} callback={updateCode} num={5} />
          <Dash className="hidden md:block" />
          <CustomInput isValid={isValid} callback={updateCode} num={6} />
          <CustomInput isValid={isValid} callback={updateCode} num={7} />
          <Dash className="hidden md:block" />
          <CustomInput isValid={isValid} callback={updateCode} num={8} />{' '}
          <CustomInput isValid={isValid} callback={updateCode} num={9} />
          <IconCheck
            color="green"
            className={
              !isValid
                ? 'opacity-0 transition-opacity delay-1000 ease-in-out w-full md:w-fit'
                : 'opacity-100 transition-opacity  ease-in-out w-full md:w-fit'
            }
          />
        </InputWrapper>

        {!isValid && hasValues && (
          <Text color="red" className="">
            Code is invalid
          </Text>
        )}
      </ProcessWrapper>
    </div>
  );
};

export default Process;
