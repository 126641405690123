import { Group, Menu, ActionIcon, Text, Indicator, Divider } from '@mantine/core';
import { useWallet } from '@solana/wallet-adapter-react';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';
import React, { useCallback } from 'react';
import { getShortAddress } from '../../utils/helpers';
import { IconLink, IconMenu, IconMenu2, IconNavigation, IconWallet } from '@tabler/icons';

interface Props {
  className?: string;
  setStep: (step: number) => void;
  zoom: () => void;
  reverse: () => void;
  restart: () => void;
}

export default function WalletButton({ className, setStep, zoom, reverse, restart }: Props) {
  const wallet = useWallet();
  const { visible, setVisible } = useWalletModal();

  const handleClick = useCallback(() => {
    setVisible(!visible);
  }, [setVisible, visible]);

  const handleDisconnectWallet = async () => {
    try {
      await wallet.disconnect();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Group position="center" className={className || 'mt-9 absolute right-7 z-20'}>
      <Menu
        control={
          <ActionIcon radius="xl" size="xl" variant="filled">
            <IconMenu2 size={30} />
          </ActionIcon>
        }
      >
        {wallet.connected ? (
          <>
            <Menu.Label>
              <Group>
                <IconMenu2 />
                <Text>Navigate</Text>
              </Group>
            </Menu.Label>
            <Divider color="white" />
            <Menu.Item onClick={() => restart()}>
              <Text className="font-semibold text-sm">Home</Text>
            </Menu.Item>
            <Menu.Item onClick={() => zoom()}>
              <Text className="font-semibold text-sm">Enlighten your Baby Ape</Text>
            </Menu.Item>
            <Menu.Item onClick={() => reverse()}>
              <Text className="font-semibold text-sm">Revert E2 to OG Ape</Text>
            </Menu.Item>
            <Divider color="white" />
            <Menu.Label>
              <Group>
                <Indicator offset={5} color={wallet.connected ? 'green' : 'red'}>
                  <IconWallet />
                </Indicator>
                <Text>Wallet</Text>
              </Group>
            </Menu.Label>
            <Divider color="white" />

            <Menu.Item>
              <Group spacing={5}>
                <Text className="text-xs">Address:</Text>{' '}
                <Text className="font-semibold text-sm">{getShortAddress(wallet?.publicKey?.toBase58() || '')}</Text>
              </Group>
            </Menu.Item>
            <Menu.Item onClick={handleDisconnectWallet}>
              <Text className="font-semibold text-sm">Disconnect</Text>
            </Menu.Item>
          </>
        ) : (
          <>
            <Menu.Label>
              <Group>
                <IconMenu2 />
                <Text>Navigate</Text>
              </Group>
            </Menu.Label>
            <Divider color="white" />
            <Menu.Item onClick={handleDisconnectWallet}>
              <Text className="font-semibold text-sm">Upgrade E1</Text>
            </Menu.Item>
            <Menu.Item onClick={handleDisconnectWallet}>
              <Text className="font-semibold text-sm">Swap E2 Images</Text>
            </Menu.Item>
            <Divider color="white" />
            <Menu.Label>
              <Group>
                <Indicator offset={5} color={wallet.connected ? 'green' : 'red'}>
                  <IconWallet />
                </Indicator>
                <Text>Wallet</Text>
              </Group>
            </Menu.Label>
            <Divider color="white" />

            <Menu.Item onClick={handleClick}>Connect</Menu.Item>
          </>
        )}
      </Menu>
    </Group>
  );
}
