import { BackgroundImage, Image, Title, UnstyledButton } from '@mantine/core';
import React, { useState } from 'react';
import { BackButton, DisplayBlock } from './styles';
import { ArrowLeft } from 'tabler-icons-react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

const Display = ({ zoom, reverse, classes, setStep }: any) => {
  const diff = dayjs(new Date()).diff(new Date('12/7/22'), 'days');
  let cost = 2500 + 130.2;
  cost = Math.round((diff * 4.2 + cost) * 10) / 10;

  return (
    <BackgroundImage src="/englighten-display.png" className={classes}>
      <div className="w-screen h-screen relative overflow-hidden">
        {/* <div className={classes} /> */}
        <div className="absolute animate-wiggle delay-1000  top-52 left-20 md:block hidden">
          <div
            className="relative bg-contain bg-no-repeat flex items-center justify-center"
            style={{ backgroundImage: 'url("/sign.png")', width: 280, height: 160 }}
          >
            {/* <Image className="" src="/sign.png" width={290} /> */}
            <Title className="transform -rotate-[7deg] pb-4 opacity-70">{cost} $DAB</Title>
          </div>
        </div>

        <UnstyledButton className="cursor-pointer" onClick={() => zoom()}>
          <Image
            className="z-10 absolute top-[40%] md:right-[40%] right-[35%] animate-pulse cursor-pointer w-[100px] md:w-[240px]"
            src="/artifact.png"
          />
        </UnstyledButton>
        <UnstyledButton className="cursor-pointer" onClick={() => reverse()}>
          <Image
            className="z-10 animate-glow absolute animate-ping-slow opacity-60 -bottom-[240px] cursor-pointer left-1/4 transition duration-300 hover:scale-110"
            width={200}
            src="/statue.png"
          />
          <Image
            className="z-10 animate-glow absolute  -bottom-[240px] cursor-pointer left-1/4 transition duration-300 hover:scale-110"
            width={200}
            src="/statue.png"
          />
        </UnstyledButton>
      </div>
    </BackgroundImage>
  );
};

export default Display;
