import { Group, Text, Indicator, Button } from '@mantine/core';
import { useWallet } from '@solana/wallet-adapter-react';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';
import React, { useCallback } from 'react';

import { IconWallet } from '@tabler/icons';
import { getShortAddress } from '../utils/helpers';

interface Props {
  className?: string;
}

export default function ConnectWallet({ className }: Props) {
  const wallet = useWallet();
  const { visible, setVisible } = useWalletModal();

  const handleClick = useCallback(() => {
    setVisible(!visible);
  }, [setVisible, visible]);

  const handleDisconnectWallet = async () => {
    try {
      await wallet.disconnect();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Group position="center" className={className || 'mt-9 absolute right-7 z-20'}>
      <Button
        leftIcon={
          <Indicator>
            <IconWallet />
          </Indicator>
        }
        onClick={wallet.connected ? handleDisconnectWallet : handleClick}
      >
        {wallet.connected ? (
          <>
            <Text className="font-semibold text-sm">
              Disconnect {getShortAddress(wallet?.publicKey?.toBase58() || '')}
            </Text>
          </>
        ) : (
          <>
            <Text>Connect Wallet</Text>
          </>
        )}
      </Button>
    </Group>
  );
}
