/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Anchor, Button, Container, Image, Modal, Stack, Title } from '@mantine/core';
import { useWallet } from '@solana/wallet-adapter-react';
import { Connection, Transaction } from '@solana/web3.js';
import { IconArrowLeft } from '@tabler/icons';
import dayjs from 'dayjs';
import React from 'react';
import endpoints from '../../../api/endpoints';
import { StepsContainer, StepsWrapper, Step, Text, DisplayBlock, EnglightenButton, ImageWrapper } from './styles';
const rpcHost = 'https://long-dawn-snowflake.solana-mainnet.quiknode.pro/29d84c5d45d688200f4bc6c0ce8bf96469d52c5b/';
const connection = new Connection(rpcHost);

const StepFour = ({ ape, tiger, setStep, setResult, type, setIsEnlightened, setTxid, setError }: any) => {
  const wallet = useWallet();
  const diff = dayjs(new Date()).diff(new Date('12/7/22'), 'days');
  let cost = 2500 + 130.2;
  cost = Math.round((diff * 4.2 + cost) * 10) / 10;
  const confirmTransaction = async (txid: string, tries = 0, id: string) => {
    if (tries === 30) {
      setError(['Transaction was not processed, please try again']);
      setTxid(txid);
      return;
    }
    const { data } = await endpoints.confirmTransaction({ txid });
    console.log(data);
    if (data && data.find((error: string) => error === 'Not Found')) {
      setTimeout(async () => {
        await confirmTransaction(txid, tries + 1, id);
      }, 5000 * tries);
    } else if (data && !data.length) {
      try {
        const newApe = await endpoints.enlightenApe({ data: { ape, type, id } });
        setIsEnlightened(newApe?.data?.json?.image !== ape?.data?.json);
        setResult(newApe.data);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleEnlighten = async () => {
    const result = await endpoints.getTransaction({
      data: { mint: tiger?.mintAddress, wallet: wallet.publicKey?.toBase58(), type },
    });
    // @ts-ignore
    const { data } = JSON.parse(result);
    if (!result) return;
    const tx = Transaction.from(Buffer.from(data));
    tx.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;
    if (!wallet?.publicKey || !wallet?.signTransaction) return;
    tx.feePayer = wallet.publicKey;
    const signed = await wallet.signTransaction(tx);
    setStep(4);
    try {
      const {
        data: { txid, error },
      } = await endpoints.processTransaction({
        data: { wallet: wallet.publicKey.toBase58(), ape, tiger, signed: signed.serialize(), type },
      });

      if (error) setError(error);
      else if (txid) await confirmTransaction(txid, 0, data?.result?.id);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Container size={1200}>
      <StepsContainer>
        <StepsWrapper>
          <Step className="mt-3">
            <Title order={5}>STEP 4</Title>
            <Text>Become Enlightened</Text>
            <DisplayBlock className="relative">
              <Button
                onClick={() => setStep(2)}
                size="xs"
                leftIcon={<IconArrowLeft />}
                className="absolute top-3 left-3"
                variant="outline"
              >
                Go Back
              </Button>
              <Title>Are You Ready?</Title>
              <ImageWrapper>
                <Image
                  className="w-[100px] h-[100px] sm:w-[180px] sm:h-[180px] bg-slate-200 rounded-lg"
                  src={ape?.json?.image || '/bg-ape.png'}
                  alt="image"
                  radius="sm"
                />
                {type === 'sacrifice' && (
                  <>
                    <Image className="w-[34px] h-[34px]" src="/plus.svg" alt="image" radius="sm" />

                    <Image
                      className="w-[100px] h-[100px] sm:w-[180px] sm:h-[180px] bg-slate-200 rounded-lg"
                      src={tiger?.json?.image || '/bg-tiger.png'}
                      alt="image"
                      radius="sm"
                    />
                  </>
                )}
              </ImageWrapper>
              <EnglightenButton onClick={handleEnlighten}>Enlighten for {cost} $DAB</EnglightenButton>
            </DisplayBlock>
          </Step>
        </StepsWrapper>
      </StepsContainer>
    </Container>
  );
};

export default StepFour;
