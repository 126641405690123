import styled from 'styled-components/macro';

export const StepsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StepsWrapper = styled.div`
  width: 100%;
  max-width: 1320px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-itens: center;
  flex-wrap: wrap;
  row-gap: 30px;

  @media (max-width: 1350px) {
    padding: 20px;
  }

  @media (max-width: 800px) {
    justify-content: center;
    column-gap: 20px;
    padding: 20px;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  column-gap: 28px;
`;

export const DecisionContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  column-gap: 28px;
  padding: 10px;
`;

export const Block = styled.div`
  width: 100%;
  // height: 100%
  min-height: 250px;
  max-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #151618;
  border-radius: 15px;
`;

export const Button = styled.button`
  width: 100%;
  height: 70px;
  font-size: 20px;
  font-weight: 600;
  border-radius: 7px;
  color: black;
  background: white;
  border-width; none;
  cursor: pointer;
`;

export const DecisionButton = styled.button`
  width: 100%;
  height: 50px;
  font-size: 20px;
  font-weight: 600;
  border-radius: 7px;
  color: black;
  background: white;
  border-width; none;
  cursor: pointer;
`;
