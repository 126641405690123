import { MantineThemeOverride } from '@mantine/core';

const theme: MantineThemeOverride = {
  // Theme is deeply merged with default theme
  cursorType: 'pointer',
  colorScheme: 'dark',
  fontFamily: 'Poppins',
  headings: { fontFamily: 'Oswald' },
  primaryColor: 'orange',
  fontSizes: {
    xl: 30,
  },
  other: {
    cardBackgroundColor: 'rgba(255, 153, 33, 0.85)',
  },
};
export default theme;
