import { Modal, Title, Button, Text, Stack, Anchor, Container, Group } from '@mantine/core';
import { useWallet } from '@solana/wallet-adapter-react';
import { error } from 'console';
import React, { useState } from 'react';
import Footer from '../../common/Footer';
import ConnectWallet from '../../components/ConnectWallet';
import Display from '../../components/Englighten/Display';
import Process from '../../components/Englighten/Process';
import RevertLayout from '../../components/Englighten/Revert/RevertLayout';
import StartingSteps from '../../components/Englighten/StartingSteps';
import StepFour from '../../components/Englighten/StepFour';
import Upgrading from '../../components/Englighten/Upgrading/Upgrading';
import WalletButton from '../../components/Englighten/WalletButton';

const Englighten = () => {
  const [ape, setApe] = useState<any>();
  const [tiger, setTiger] = useState<any>();
  const [step, setStep] = useState(0);
  const [result, setResult] = useState();
  const [type, setType] = useState('');
  const [enlightend, setIsEnlightened] = useState(false);
  const [error, setError] = useState<string[]>([]);
  const [txid, setTxid] = useState('');
  const wallet = useWallet();

  const initClasses = 'opacity-100 ';
  const newClasses = 'duration-1000 transition-all opacity-0';
  const [classes, setClasses] = useState(initClasses);

  const restart = () => {
    setStep(0);
    setClasses(initClasses);
  };

  const zoom = () => {
    setClasses(newClasses);
    setStep(1);
  };

  const reverse = () => {
    setClasses(newClasses);
    setStep(6);
  };

  const handlTryAgain = () => {
    setError([]);
    setTxid('');
    setStep(3);
  };

  return (
    <div>
      <WalletButton setStep={setStep} zoom={zoom} reverse={reverse} restart={restart} />
      <Display setStep={setStep} classes={classes} zoom={zoom} reverse={reverse} />
      <div
        className={
          step === 1
            ? 'duration-500 transition-opacity opacity-100 delay-500 top-0 absolute w-screen h-screen'
            : 'duration-500 transition-opacity opacity-0 top-0 -z-10 absolute w-screen h-screen'
        }
      >
        <Process setStep={setStep} step={step} />
      </div>
      <div
        className={
          step === 2
            ? 'duration-500 transition-opacity opacity-100 delay-500 top-0 absolute w-screen h-screen'
            : 'h-0 md:h-screen duration-500 transition-opacity opacity-0 top-0 -z-10 absolute w-screen'
        }
      >
        <StartingSteps
          ape={ape}
          setApe={setApe}
          tiger={tiger}
          setTiger={setTiger}
          setType={setType}
          setStep={setStep}
          step={step}
          type={type}
        />
      </div>

      <div
        className={
          step === 3
            ? 'duration-1000 transition-opacity opacity-100 delay-1000 top-0 absolute w-screen h-screen'
            : 'h-0 md:h-screen duration-1000 transition-opacity opacity-0 top-0  -z-10 absolute w-screen'
        }
      >
        <StepFour
          type={type}
          ape={ape}
          tiger={tiger}
          setStep={setStep}
          setResult={setResult}
          setIsEnlightened={setIsEnlightened}
          setError={setError}
          setTxid={setTxid}
        />
      </div>

      <div
        className={
          step === 4
            ? 'duration-1000 transition-opacity opacity-100 delay-500 top-0 absolute w-screen h-screen'
            : 'h-0 md:h-screen duration-500 transition-opacity opacity-0 top-0  -z-10 absolute w-screen'
        }
      >
        <Upgrading
          result={result}
          ape={ape}
          setStep={setStep}
          setTiger={setTiger}
          setApe={setApe}
          setType={setType}
          enlightend={enlightend}
          setResult={setResult}
        />
      </div>
      <div
        className={
          step === 6
            ? 'duration-1000 transition-opacity opacity-100 delay-500 top-0 absolute w-screen h-screen'
            : 'h-0 md:h-screen duration-500 transition-opacity opacity-0 top-0  -z-10 absolute w-screen'
        }
      >
        {wallet.connected && <RevertLayout />}
        {!wallet.connected && (
          <Container className="h-screen">
            <Group className="h-full" align="center" position="center">
              <Text>Connect wallet to continue</Text>
              <ConnectWallet className="w-fit" />
            </Group>
          </Container>
        )}
      </div>
      <Modal
        opened={error && !!error.length}
        onClose={() => setError([])}
        title={<Title order={2}>Error Processing Transaction</Title>}
      >
        <Stack>
          <Text>
            Unable to enlighten your Ape. See the errors below and please try again. Your DAB is safe and your Tiger has
            not been burned. This is due to a network error.
          </Text>
          {txid && (
            <Anchor href={`https://solscan.io/tx/${txid}`} target="_blank">
              <Text>Transaction Id: {txid}</Text>
            </Anchor>
          )}
          {error.map((e, i) => {
            return <Text key={i}>{error}</Text>;
          })}
          <Button onClick={() => handlTryAgain()}>Try Again</Button>
        </Stack>
      </Modal>
    </div>
  );
};

export default Englighten;
