import React, { useEffect } from 'react';
import { Modal, Title } from '@mantine/core';
import ChooseApe from '../ChooseApe';
import useApi from '../../../hooks/useApi';
import endpoints from '../../../api/endpoints';
import { useWallet } from '@solana/wallet-adapter-react';

const TigerModal = ({ isSelect, setIsSelect, callback }: any) => {
  const { data, request: getTigers, loading } = useApi(endpoints.getTigers);
  const wallet = useWallet();

  useEffect(() => {
    if (wallet.publicKey) getTigers({ wallet: wallet.publicKey?.toBase58() });
  }, [wallet?.publicKey, isSelect]);

  return (
    <>
      <Modal
        title={<Title order={2}>{!data || !data.length ? 'Uh Oh...' : 'Select your companion'}</Title>}
        opened={isSelect}
        onClose={() => setIsSelect(false)}
        centered
        size={528}
      >
        <ChooseApe
          title="Choose a tiger"
          callback={callback}
          apes={data}
          loading={loading}
          closeModal={() => setIsSelect(false)}
        />
      </Modal>
    </>
  );
};

export default TigerModal;
